import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { about, mobileAbout } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="aboutImageMobile" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView row justifyStart w="100%" maxWidth="1400px">
          <CFImage src={about} w="100%" alt="about Image" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
